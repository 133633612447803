// var stickyHeader = false;
var transformHomepageDonationAmountImages = true;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links

// // Basket in mainCallToAction
// if( $(".menuAdminContainer").css('display') == 'none') {
//   $("#menuAdmin .menuAdminBasket").prependTo(".mainCallToAction ul.socialIcons");
// }

$( document ).ready(function() {
  $(".homefeaturecategory-homeboximpactstats").insertBefore(".homefeaturecategory-homeboxquickgiving");
});
